import { useForm } from '@mantine/form'
import {
  Alert,
  ArrowLeftIcon,
  Group,
  InfoIcon,
  PrimaryButton,
  Radio,
  RadioGroup,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import { INSURANCE_OTHER_OPTION, getPayersShortList } from '@shared/utils'
import React, { useMemo } from 'react'
import { InsuranceSelect } from '../../../components'
import { getSessionStorageItem, setSessionStorageItem } from '../../../storage'
import { WidgetProps } from '../PromptContentWidget'

type PaymentForm = {
  payment_method: PromptResponseContextKey | undefined
  insurance_provider: string
}

const PAYMENT_METHOD_INSURANCE: PromptResponseContextKey = 'payment_method_insurance'
const PAYMENT_METHOD_INSURANCE_OTHER: PromptResponseContextKey = 'payment_method_insurance_other'
const PAYMENT_METHOD_CASH: PromptResponseContextKey = 'payment_method_cash'

export const PaymentMethod = ({ response, onBack, onSubmit }: WidgetProps) => {
  const residenceState = getSessionStorageItem('residence_state') || ''

  const insurances = useMemo(() => getPayersShortList(residenceState), [residenceState])

  const form = useForm<PaymentForm>({
    initialValues: {
      payment_method:
        response?.value.contextKey === PAYMENT_METHOD_INSURANCE_OTHER
          ? PAYMENT_METHOD_INSURANCE
          : // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            (response?.value.contextKey as PromptResponseContextKey | undefined),
      insurance_provider:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        (response?.insurance_provider?.value as PromptResponseContextKey | undefined) || '',
    },
    validate: values => {
      // User must select an insurance provider from the shortlist.
      const requireInsuranceProvider =
        values.payment_method === 'payment_method_insurance' &&
        values.insurance_provider !== INSURANCE_OTHER_OPTION.value

      if (requireInsuranceProvider) {
        return {
          payment_method: values.payment_method ? null : 'Please select one',
          insurance_provider: values.insurance_provider ? null : 'Please select one',
        }
      }

      // In this scenario, the user selects to pay out of pocket and therefore the only validation is on the payment_method field.
      return {
        insurance_payment_method: values.payment_method ? null : 'Please select one',
        insurance_provider: null,
      }
    },
  })

  const insuranceOtherOptionValue = `${INSURANCE_OTHER_OPTION.value}__${INSURANCE_OTHER_OPTION.label}`
  const hasSelectedInsuranceFromShortlist =
    form.values.insurance_provider && form.values.insurance_provider !== insuranceOtherOptionValue
  const hasSelectedOtherInsurance = form.values.insurance_provider === insuranceOtherOptionValue

  const submitHandler = () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }
    if (!form.values.payment_method) {
      return
    }

    if (form.values.payment_method === PAYMENT_METHOD_CASH) {
      setSessionStorageItem('intended_payment_method', 'Cash')

      onSubmit({
        value: {
          contextKey: form.values.payment_method,
        },
      })
    } else if (form.values.payment_method === PAYMENT_METHOD_INSURANCE) {
      setSessionStorageItem('intended_payment_method', 'Insurance')
      setSessionStorageItem('insurance_provider', form.values.insurance_provider)
      if (hasSelectedOtherInsurance) {
        onSubmit({
          value: {
            contextKey: PAYMENT_METHOD_INSURANCE_OTHER,
          },
          insurance_provider: {
            contextKey: 'custom',
            value: form.values.insurance_provider,
          },
        })
      } else {
        onSubmit({
          value: {
            contextKey: form.values.payment_method,
          },
          insurance_provider: {
            contextKey: 'custom',
            value: form.values.insurance_provider,
          },
        })
      }
    }
  }

  return (
    <Stack spacing='lg' test-id='content:payment-method'>
      <RadioGroup test-id='radio-group:payment-method' {...form.getInputProps('payment_method')}>
        <Radio
          value={PAYMENT_METHOD_INSURANCE}
          label='Yes, I have health insurance'
          description='Pay your out of pocket costs'
        />
        <Radio
          value={PAYMENT_METHOD_CASH}
          label="No, I'll pay without insurance"
          description='$195/month'
        />
      </RadioGroup>
      {form.values.payment_method === 'payment_method_cash' && (
        <Alert
          icon={<InfoIcon />}
          variant='secondary'
          title='Payment is only required if you decide to enroll after meeting a clinician.'
        >
          <Text size='xs'>
            Otherwise, there&apos;s no charge and we&apos;ll help with other resources.
          </Text>
        </Alert>
      )}
      {form.values.payment_method === 'payment_method_insurance' && (
        <InsuranceSelect
          test-id='input:insurance'
          success={hasSelectedInsuranceFromShortlist && `We're in-network with your insurance`}
          warning={hasSelectedOtherInsurance && `We'll review your insurance on your welcome call`}
          data={insurances}
          {...form.getInputProps('insurance_provider')}
          placeholder='Type to find your plan...'
          rightSection={<> </>}
          searchable
          clearable
        />
      )}
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Next
            </PrimaryButton>
            {onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Group position='apart'>
            {onBack && (
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            )}
            <Group position='right'>
              <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
